import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import AuthLayout from '../views/layouts/AuthLayout';

const AuthRoute = ({component: Component, token, ...rest}) => {
  return(
      <Route
          {...rest}
          render={ matchProps => (
              <Fragment>
                { !token ?
                    <AuthLayout {...rest}>
                      <Component {...matchProps} />
                    </AuthLayout>
                    :
                    <Redirect
                        to={{
                          pathname: "/panel/dashboard",
                          // state: { referrer: currentLocation }
                        }}
                    />
                }
              </Fragment>
          )}
      >

      </Route>
  );
}
// console.log(matchProps)
const mapStateToProps = state => ({
  token: state.user.token
})

export default connect(
    mapStateToProps
)(AuthRoute);
