import React, { useEffect, useState } from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory } from "react-router";
import { deleteCategoryById, fetchCategory } from "../../../services/category.service";
import { useSelector } from "react-redux";

const CategoryPage = () => {
  const userData = useSelector(state => state.user.user);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataTmp, setCategoryDataTmp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (userData.role.label === 'admin') {
      setIsAdmin(true);
    }
    categoriesData();
  }, [userData, history, isAdmin]);

  const categoriesData = () => {
    setLoading(true);
    fetchCategory().then(res => {
      setCategoryDataTmp(res.data.data);
      setCategoryData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleChange = async (event) => {
    await setSearch(event.target.value)
    let searchRes = categoryDataTmp;

    if (!event.target.value) {
      setCategoryData(categoryDataTmp);
      return;
    }

    const searchRes2 = searchRes.filter((val) => {
      return val.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
        val.code.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
    });
    setCategoryData(searchRes2);
  }

  const deleteHandler = (id) => {
    // console.log(id);
    // eslint-disable-next-line no-restricted-globals
    const r = confirm("are you sure to delete this data?");
    if (!r) {
      return;
    }
    deleteCategoryById(id, {}).then(res => {
      console.log(res);
      alert('delete data success');
      categoriesData();
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally(() => {
      setLoading(false);
    });
  }

  const createNewCategoryHandler = () => {
    console.log('new');
    history.push('/panel/categories/new');
  }

  const updateCategoryHandler = (id) => {
    console.log('new');
    history.push('/panel/categories/edit/' + id);
  }

  const loopCategory = () => {
    return categoryData.map((val, i) => {
      return (
        <tr key={i}>
          <td>{val._id}</td>
          <td>{val.code}</td>
          <td>{val.name}</td>
          {isAdmin && <td>
            <span className="badge bg-danger mr-1" onClick={() => { deleteHandler(val._id) }}>
              <i className="fas fa-trash-alt p-1" aria-hidden="true" />
            </span>

            <span className="badge bg-success mr-1" onClick={() => { updateCategoryHandler(val._id) }}>
              <i className="fas fa-pencil-alt p-1" aria-hidden="true" />
            </span>
          </td>}
        </tr>
      );
    })
  }

  return (
    <div className="row">
      {isAdmin &&
        <div className="col-8">
          <input
            type="text"
            name="table_search"
            className="form-control float-right p-1"
            value={search}
            onChange={handleChange}
            placeholder="Search" />
        </div>
      }
      {isAdmin &&
        <div className="col-4">
          <button type="button" className="btn btn-block btn-primary" onClick={() => { createNewCategoryHandler() }}>
            <i className='fas fa-plus' aria-hidden='true' />
          </button>
        </div>
      }
      {!isAdmin &&
        <div className="col-12">
          <input
            type="text"
            name="table_search"
            className="form-control float-right p-1"
            value={search}
            onChange={handleChange}
            placeholder="Search" />
        </div>
      }
      <div className="col-12">
        <hr className='hr-user' />
      </div>
      <div className="col-12">
        <div className="card-body table-responsive p-0">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Code</th>
                <th>Name</th>
                {isAdmin && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {loading && <tr><td colSpan='6'>Load Data</td></tr>}
              {(!loading && categoryData.length > 0) && loopCategory()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

let pageSet = {
  title: "Category",
  location: '/panel/categories'
}

export default withHeader(pageSet)(CategoryPage)
