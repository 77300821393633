import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory, useParams } from "react-router";
import {fetchMerchantProductById, updateMerchantProduct} from "../../../services/merchant-product.service";
import {fetchProduct} from "../../../services/products.service";
import {fetchMerchants} from "../../../services/merchant.service";
import Select  from "react-select";
import { fetchBanks } from '../../../services/bank.service';

const MerchantProdEditPage = () => {
  const [reqParams, setReqParams] = useState({
    merchant_code: '',
    product_code: '',
    bank_code: '',
    fee_merchant: 0,
    fee_kilat: 0,
    fee_biller: 0,
    percent_price: 0,
  });

  let { id } = useParams();
  const history = useHistory();
  const [prodData, setProdData] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [bankData, setBankData] = useState([]);
  useEffect((idParams = id) => {
    merchantProdData(idParams);
    products();
    merchants();
    banks();
  }, [id]);

  const merchantProdData = (id) => {
    fetchMerchantProductById(id).then(res => {
      // console.log('res', res.data)
      let data = res.data.data;
      data.merchant_code = res.data.data.merchant_code ? res.data.data.merchant_code : '';
      data.product_code = res.data.data.product_code ? res.data.data.product_code : '';
      data.bank_code = res.data.data.bank_code ? res.data.data.bank_code : '';
      data.fee_merchant = res.data.data.fee_merchant ? res.data.data.fee_merchant : 0;
      data.percent_price = res.data.data.percent_price ? res.data.data.percent_price : 0;
      delete data._id;
      delete data.__v;
      delete data.merchantProdData;
      setReqParams(data)
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }
  const banks = () => {
    fetchBanks().then(res => {
      setBankData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }
  
  const products = () => {
    fetchProduct().then(res => {
      setProdData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const merchants = () => {
    fetchMerchants().then(res => {
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const MerchantOptions = merchantData.map(value => {
    return {value: value.kode, label: value.name, name: 'merchant_code', key:value._id}
  })

  const MerchantSelectComponent = () => (
      <Select
          {...MerchantOptions}
          options={MerchantOptions}
          value={
            MerchantOptions.filter(option =>
            option.value === reqParams.merchant_code)
          }
          placeholder='select Merchant'
          id="merchant_code"
          name='merchant_code'
          onChange={handleChangeProd}
          required
      />
  )

  const ProdCodeOptions = prodData.map(value => {
    return {value: value.kode, label: value.name, name: 'product_code', key:value._id}
  })

  const ProductCodeSelectComponent = () => (
      <Select
          {...ProdCodeOptions}
          options={ProdCodeOptions}
          placeholder='select Product'
          value={
            ProdCodeOptions.filter(option =>
              option.value === reqParams.product_code)
          }
          id="product_code"
          name='product_code'
          onChange={handleChangeProd}
          required
      />
  )
  const BankOptions = bankData.map(value => {
    return {value: value.kode, label: value.name, name: 'bank_code', key:value._id}
  })
  const BanksSelectComponent = () => (
    <Select
        {...BankOptions}
        options={BankOptions}
        placeholder='select Bank'
        value={
          BankOptions.filter(option =>
              option.value === reqParams.bank_code)
        }
        id="bank_code"
        name='bank_code'
        onChange={handleChangeProd}
        required
    />
)
  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
  }

  const handleChangeProd = (event) => {
    // console.log("changee", event)
    const params = {...reqParams}
    params[event.name] = event.value;
    setReqParams(params);
    // console.log(params)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('reqParams', reqParams);
    console.log('id', id);

    // return false;
    try {
      reqParams.percent_price = Number(reqParams.percent_price);
      reqParams.fee_merchant = Number(reqParams.fee_merchant);
      reqParams.fee_kilat = Number(reqParams.fee_kilat);
      reqParams.fee_biller = Number(reqParams.fee_biller);
      let result = await updateMerchantProduct(id, reqParams);
      console.log(result);
      if (result.status === 200 && result.data.message === 'updated') {
        alert('Success Update Merchant');
        history.push('/panel/merchant-prods');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
    }
  }

  const handleBack = () => {
    // console.log(123);
    history.push('/panel/merchant-prods');
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="merchant_code">merchant_code</label>
                {MerchantSelectComponent()}
                {/*<select
                    className="w-100 p-2"
                    id="merchant_code"
                    value={reqParams.merchant_code}
                    name='merchant_code'
                    onChange={handleChange}
                    required>
                  <option>-----Select Merchant-----</option>
                  {merchantData.map(val => {
                    return (<option value={val.kode} key={val._id}>{val.name}</option>);
                  })}
                </select>*/}
              </div>
              <div className="form-group">
                <label htmlFor="product_code">product_code</label>
                {ProductCodeSelectComponent()}
                {/*<select
                    className="w-100 p-2"
                    id="product_code"
                    name='product_code'
                    value={reqParams.product_code}
                    onChange={handleChange}
                    required>
                  <option>-----Select Product-----</option>
                  {prodData.map(val => {
                    return (<option value={val.kode} key={val._id}>{val.name}</option>);
                  })}
                </select>*/}
              </div>
              <div className="form-group">
                <label htmlFor="bank_code">bank_code</label>
                {/* <input
                    type="text"
                    className="form-control"
                    id="bank_code"
                    name='bank_code'
                    value={reqParams.bank_code}
                    onChange={handleChange}
                    placeholder="Enter bank_code"
                /> */
                BanksSelectComponent()
                }
              </div>
              <div className="form-group">
                <label htmlFor="fee_kilat">fee_kilat</label>
                <input
                    type="text"
                    className="form-control"
                    id="fee_kilat"
                    name='fee_kilat'
                    placeholder="Enter fee_kilat"
                    value={reqParams.fee_kilat}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="fee_biller">fee_biller</label>
                <input
                    type="text"
                    className="form-control"
                    id="fee_biller"
                    name='fee_biller'
                    placeholder="Enter fee_biller"
                    value={reqParams.fee_biller}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="fee_merchant">fee_merchant</label>
                <input
                    type="text"
                    className="form-control"
                    id="fee_merchant"
                    name='fee_merchant'
                    placeholder="Enter fee_merchant"
                    value={reqParams.fee_merchant}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="percent_price">percent_price</label>
                <input
                    type="text"
                    className="form-control"
                    id="percent_price"
                    name='percent_price'
                    placeholder="Enter percent_price"
                    value={reqParams.percent_price}
                    onChange={handleChange}
                    required
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Update Merchant Product",
  location: '/panel/merchant-prods/edit/id'
}

export default withHeader(pageSet)(MerchantProdEditPage)
