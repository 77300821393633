import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
// import {useHistory} from "react-router";
import {fetchTrx} from "../../../services/trx.service";
import moment from 'moment';
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import Select from "react-select";
import {statusDeposit} from "../../../assets/static/data";

const DepositPage = () => {
  const userData = useSelector(state => state.user.user);
  const [trxData, setTrxData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    tx_id: '',
    status: '',
    from_date: moment().format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    type: '2',
  });

  const history = useHistory();

  useEffect((searchParams = search) => {
    if (userData.role.label === 'ca') {
      history.push('/panel/dashboard-ca');
    }

    if (searchParams.from_date.length === 10 && searchParams.to_date.length === 10) {
      if (!searchParams.tx_id) {
        trxHistData(searchParams);
      }
    }

    if (searchParams.tx_id.length === 24) {
      trxHistData(searchParams);
    }
    // trxHistData(searchParams);
  }, [search, history, userData]);

  const trxHistData = (searchParams) => {
    setLoading(true);
    fetchTrx(searchParams).then(res => {
      setTrxData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const selectStatus = () => {
    const options = statusDeposit().map(val => {
      return {
        value: val.id,
        label: val.name
      }
    })

    return (
        <Select
            value={options.value}
            options={options}
            placeholder='select Status'
            onChange={handleChangeStatus}
        />
    )
  }

  const handleChangeStatus = (event) => {
    // console.log("status", event)
    const params = {...search}
    params.status = event.value;
    setSearch(params);
  }

  const handleChange = (event) => {
    const params = {...search}
    params[event.target.name] = event.target.value;
    setSearch(params);
    console.log(params)
  }

  const detailDepositHandler = (id) => {
    console.log('edit');
    history.push('/panel/deposit/' + id);
  }

  const currencyFormat = (num) => {
    if (!num) {
      return;
    }

    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const loopTrx = () => {
    return trxData.map((val, i) => {
      if (val.status === '3') {
        val.status = 'inquiry';
      } else if (val.status === '0') {
        val.status = 'success';
      }

      return (
          <tr key={i}>
            <td>{ val._id }</td>
            <td>{ val.tx_id }</td>
            <td>{ val.merchant_id }</td>
            <td>{ val.status }</td>
            <td>{ currencyFormat(val.total_amount) }</td>
            <td>{ currencyFormat(val.first_balance) }</td>
            <td>{ currencyFormat(val.last_balance) }</td>
            <td>{ val.created_at }</td>
            <td>
              <span className="badge bg-success" onClick={() => { detailDepositHandler(val._id)}}>
                <i className="fas fa-edit p-1" aria-hidden="true" />
              </span>
            </td>
          </tr>
      );
    })
  }

  return (
      <div className="row">
        <div className="col-6 mb-2">
          <input
              type="text"
              name="tx_id"
              className="form-control float-right p-1"
              placeholder="Search Tx Id"
              value={search.tx_id}
              onChange={handleChange}
          />
        </div>
        <div className="col-6 mb-2">
          {selectStatus()}
          {/*<select
              name="status"
              className="form-control float-right p-1"
              value={search.status}
              onChange={handleChange}
          >
            <option value="">------Select Status------</option>
            <option value="3">Inquiry</option>
            <option value="0">Success</option>
            <option value="4">Reversal</option>
          </select>*/}
        </div>
        <div className="col-6">
          <input
              type="text"
              name="from_date"
              className="form-control float-right p-1"
              placeholder="From Date"
              value={search.from_date}
              onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <input
              type="text"
              name="to_date"
              className="form-control float-right p-1"
              placeholder="To Date"
              value={search.to_date}
              onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <hr className='hr-user' />
        </div>
        <div className="col-12">
          <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>ID</th>
                <th>TxId</th>
                <th>Merchant</th>
                <th>Status</th>
                <th>Amount</th>
                <th>First Balance</th>
                <th>Last Balance</th>
                <th>Created Date</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              { loading && <tr><td colSpan='6'>Load Data</td></tr> }
              { (!loading && trxData.length > 0) && loopTrx() }
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Deposit Page",
  location: '/panel/deposit'
}

export default withHeader(pageSet)(DepositPage)
