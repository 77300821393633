import {Delete, Get, Post, ProxyReq, Put} from "./config";

const API_VER = process.env.REACT_APP_API_VER;

// Fetch users data
// export const fetchUsers = (params) => Get( API_VER + '/users', params);

// Fetch Roles
export const fetchRoles = () => ProxyReq('GET', 'https://users.aliansijump.com/' + API_VER + '/roles', {});

// User Endpoint
export const storeUser = (params) => Post('users', params);
export const currentUser = (params) => Get('users/current', params);
export const updateUser = (id, params) => Put('users', id, params);
export const fetchUsers = (params) => Get( 'users', params);
export const fetchUsersById = (id) => Get( 'users/' + id);
export const deleteUsersById = (id, params) => Delete( 'users/' + id, params);
