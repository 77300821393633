import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {useHistory} from "react-router";
import {deleteMerchantProductById, fetchMerchantsProduct} from "../../../services/merchant-product.service";
import {useSelector} from "react-redux";
import {fetchProduct} from "../../../services/products.service";
import {fetchMerchants} from "../../../services/merchant.service";
import Select from "react-select";
import {fetchBanks} from "../../../services/bank.service";

const MerchantProductPage = () => {
  const userData = useSelector(state => state.user.user);
  const [merchantProdData, setMerchantProdData] = useState([]);
  const [merchantProdDataTmp, setMerchantProdDataTmp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [prodData, setProdData] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [merchant, setMerchant] = useState('');
  const [bankData, setBankData] = useState([]);
  const [bank, setBank] = useState('');
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(20);

  const history = useHistory();

  useEffect(() => {
    if (userData.role.label === 'ca') {
      history.push('/panel/dashboard-ca');
    }
    merchantProductData();
    products();
    merchants();
    banks();
  }, [history, userData]);

  const merchantProductData = () => {
    setLoading(true);
    fetchMerchantsProduct().then(res => {
      setMerchantProdData(res.data.data);
      setMerchantProdDataTmp(res.data.data);
      setTotalPage(res.data.count);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const products = () => {
    fetchProduct().then(res => {
      setProdData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const merchants = () => {
    fetchMerchants().then(res => {
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const banks = () => {
    fetchBanks().then(res => {
      setBankData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const handleChange = (event) => {
    setSearch(event.target.value)
    let searchRes = merchantProdDataTmp;

    if (!event.target.value) {
      setMerchantProdData(merchantProdDataTmp)
      return;
    }

    const searchRes2 = searchRes.filter((val) => {
      let prod_name = '';
      let merchant_name = '';
      prodData.forEach(val2 => {
        if (val2.kode === val.product_code) {
          prod_name = val2.name;
        }
      });

      merchantData.forEach(val3 => {
        if (val3.kode === val.merchant_code) {
          merchant_name = val3.name;
        }
      });

      return prod_name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
          merchant_name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
    });
   setMerchantProdData(searchRes2);
  }

  const deleteHandler = (id) => {
    // console.log(id);
    // eslint-disable-next-line no-restricted-globals
    const r = confirm("are you sure to delete this data?");
    if (!r) {
      return;
    }
    deleteMerchantProductById(id, {}).then(async res => {
      console.log(res);
      alert('delete data success');
      // merchantProductData();
      let params = 'page=' + page;
      if (merchant) {
        params += '&merchant_code=' + merchant;
      }
      if (bank) {
        params += '&bank_code=' + bank;
      }
      const searchProd2 = await fetchMerchantsProduct(params);
      setMerchantProdData(searchProd2.data.data);
      setMerchantProdDataTmp(searchProd2.data.data);
      setTotalPage(searchProd2.data.count);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const createNewMerProdHandler = () => {
    console.log('new');
    history.push('/panel/merchant-prods/new');
  }

  const updateHandler = (id) => {
    console.log('new');
    history.push('/panel/merchant-prods/edit/' + id);
  }

  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }
    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const loopBank = () => {
    return merchantProdData.map((val, i) => {
      let prod_name = '-';
      let merchant_name = '-';
      prodData.forEach(val2 => {
        if (val2.kode === val.product_code) {
          prod_name = val2.name;
        }
      });

      merchantData.forEach(val3 => {
        if (val3.kode === val.merchant_code) {
          merchant_name = val3.name;
        }
      });


      if (page === 1) {
        val.no = i + 1;
      } else {
        const limit = 20 * (page - 1);
        val.no = i + 1 + limit;
      }

      return (
          <tr key={val._id}>
            <td>{ val.no }</td>
            <td>{ merchant_name }</td>
            <td>{ prod_name }</td>
            <td>{ val.bank_code }</td>
            <td className='text-right'>{ currencyFormat(val.fee_merchant) }</td>
            <td className='text-right'>{ currencyFormat(val.percent_price) }</td>
            <td className='text-right'>{ currencyFormat(val.fee_kilat) }</td>
            <td className='text-right'>{ currencyFormat(val.fee_biller) }</td>
            <td>
              <span className="badge bg-danger mr-1" onClick={() => { deleteHandler(val._id)}}>
                <i className="fas fa-trash-alt p-1" aria-hidden="true" />
              </span>

              <span className="badge bg-success mr-1" onClick={() => {updateHandler(val._id)}}>
                <i className="fas fa-pencil-alt p-1" aria-hidden="true" />
              </span>
            </td>
          </tr>
      );
    })
  }

  const merchantOptions = merchantData.map(value => {
    return { value: value.kode, label: value.name, name: 'merchant_code', key: value._id }
  });
  const merchantSelectComponent = () => (
      <Select isClearable options={merchantOptions} placeholder='select Merchant' id="merchant_code" name='merchant_code' onChange={handleChangeMerchant} required />
  )

  const handleChangeMerchant = async (event) => {
    setPage(1);
    let params = 'page=1';
    if (bank) {
      params += '&bank_code=' + bank;
    }
    setLoading(true);
    let searchProd2 = [];
    if (event === null) {
      setMerchant('');
      try {
        searchProd2 = await fetchMerchantsProduct(params);
        setPage(1);
        setLoading(false);
      } catch (e) {
        console.log(e);
        alert('load data error');
      }
    } else {
      setMerchant(event.value);
      params = params + '&merchant_code=' + event.value;
      try {
        searchProd2 = await fetchMerchantsProduct(params);
        setPage(1);
        setLoading(false);
      } catch (e) {
        console.log(e);
        alert('load data error');
      }
    }
    console.log('s :>> ', searchProd2);

    setMerchantProdData(searchProd2.data.data);
    setMerchantProdDataTmp(searchProd2.data.data);
    setTotalPage(searchProd2.data.count);
  }

  const bankOptions = bankData.map(value => {
    return { value: value.kode, label: value.name, name: 'bank_code', key: value._id }
  });

  const bankSelectComponent = () => (
    <Select isClearable options={bankOptions} placeholder='select Bank' id="bank_code" name='bank_code' onChange={handleChangeBank} required />
  )

  const handleChangeBank = async (event) => {
    setPage(1);
    let params = 'page=1';
    if (merchant) {
      params += '&merchant_code=' + merchant;
    }
    setLoading(true);
    let searchProd2 = [];
    if (event === null) {
      setBank('');
      try {
        searchProd2 = await fetchMerchantsProduct(params);
        setPage(1);
        setLoading(false);
      } catch (e) {
        console.log(e);
        alert('load data error');
      }
    } else {
      setBank(event.value);
      params = params + '&bank_code=' + event.value;
      try {
        searchProd2 = await fetchMerchantsProduct(params);
        setPage(1);
        setLoading(false);
      } catch (e) {
        console.log(e);
        alert('load data error');
      }
    }
    console.log('s :>> ', searchProd2);

    setMerchantProdData(searchProd2.data.data);
    setMerchantProdDataTmp(searchProd2.data.data);
    setTotalPage(searchProd2.data.count);
  }

  const loopPagination = () => {
    const pagination = Math.ceil(totalPage / 20);
    const arr = [];
    let arr2 = [];
    for (let i = 0; i < pagination; i++) {
      arr.push(i);
    }

    arr.forEach((val, i) => {
      if ((page + 5) - (i + 1) >= 0) {
        if (page < 7) {
          arr2.push(i);
        } else if ((page - 5) - (i + 1) <= 0) {
          arr2.push(i);
        }
      }
    });

    return arr2.map((val, i) => {
      return (
          <li className={ page === val + 1 ? 'page-item active' : 'page-item' } key={'pagination_' + i}>
            <span className="page-link" onClick={() => handleChangePage(val + 1)}>{ val + 1 }</span>
          </li>
      );
    });
  }

  const handleChangePage = async (currentPage = 1) => {
    let searchProd2 = {};
    setPage(currentPage);

    let params = 'page=' + currentPage;
    if (merchant) {
      params += '&merchant_code=' + merchant;
    }
    if (bank) {
      params += '&bank_code=' + bank;
    }
    setLoading(true);
    try {
      searchProd2 = await fetchMerchantsProduct(params);
      setPage(currentPage);
      setLoading(false);
    } catch (e) {
      console.log(e);
      alert('load data error');
    }


    console.log('s :>> ', searchProd2);

    setMerchantProdData(searchProd2.data.data);
    setMerchantProdDataTmp(searchProd2.data.data);
  }

  const loopPagination2 = () => {
    return (
        <li className="page-item" key={'pagination_2_xyz'}>
          <span className="page-link" >...</span>
        </li>
    );
  }

  const loopPagination3 = () => {
    if (page < 7) {
      return ;
    }
    return (
      <li className="page-item" key={'pagination_3_xyz'}>
        <span className="page-link" >...</span>
      </li>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-4 mb-2">
          {merchantSelectComponent()}
        </div>
        <div className="col-sm-4 mb-2">
          {bankSelectComponent()}
        </div>
        <div className="col-sm-4 mb-2">
          <input
              type="text"
              name="table_search"
              className="form-control float-right p-1"
              value={search}
              onChange={handleChange}
              placeholder="Search" />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <button type="button" className="btn btn-block btn-primary" onClick={() => {createNewMerProdHandler()}}>
            <i className='fas fa-plus' aria-hidden='true' />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className='hr-user' />
        </div>
        <div className="col-12">
          <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap table-sm">
              <thead>
              <tr>
                <th>ID</th>
                <th>Merchant Code</th>
                <th>Product Code</th>
                <th>Bank Code</th>
                <th className='text-right'>Fee Merchant</th>
                <th className='text-right'>Percent Price</th>
                <th className='text-right'>Fee Kilat</th>
                <th className='text-right'>Fee Biller</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              { loading && <tr><td colSpan='6'>Load Data</td></tr> }
              { (!loading && merchantProdData.length > 0) && loopBank() }
              </tbody>
            </table>

            <nav aria-label="Page navigation example" >
              <ul className="pagination">
                <li className="page-item">
                  <span className="page-link" onClick={() => page === 1 ? '' : handleChangePage(page - 1)}>Previous</span>
                </li>
                {loopPagination3()}
                {(!loading && merchantData.length > 0) && loopPagination()}
                {loopPagination2()}
                <li className="page-item">
                  <span className="page-link" onClick={() => handleChangePage(page + 1)}>Next</span>
                </li>
              </ul>
            </nav>

          </div>
        </div>
      </div>
    </div>
  );
}

let pageSet = {
  title: "Merchant Product",
  location: '/panel/merchant-prods'
}

export default withHeader(pageSet)(MerchantProductPage)
