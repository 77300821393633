import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory, useParams } from "react-router";
import {fetchTrxById, updateDeposit} from "../../../services/trx.service";
import {useSelector} from "react-redux";

const DepositDetailPage = () => {
  const userData = useSelector(state => state.user.user);
  const [reqParams, setReqParams] = useState({
    amount: 0,
    image: '',
    status: '',
  });

  const [image, setImage] = useState('');
  let { id } = useParams();
  const history = useHistory();

  useEffect((idParams = id) => {
    if (userData.role.label === 'ca') {
      history.push('/panel/dashboard-ca');
    }
    trxDepositData(idParams);
  }, [id, userData, history]);

  const trxDepositData = (id) => {
    fetchTrxById(id).then(res => {
      // console.log('res', res.data)
      let data = res.data.data;
      data.amount = res.data.data.amount ? res.data.data.amount : '';
      data.image = res.data.data.tx_detail ? res.data.data.tx_detail : '';
      data.status = res.data.data.status ? res.data.data.status : '';
      delete data._id;
      delete data.__v;
      delete data.trxDepositData;
      setReqParams(data)
      setImage(data.image);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }

  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
    console.log(params)
  }

  const handleBack = () => {
    // console.log(123);
    history.push('/panel/deposit');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log('reqParams', reqParams);
    // console.log('id', id);
    delete reqParams.tx_detail;
    delete reqParams.image;

    // return false;
    try {
      let result = await updateDeposit(id, reqParams);
      console.log(result);
      if (result.status === 200 && result.data.message === 'updated') {
        alert('Success Update Deposit');
        history.push('/panel/deposit');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
    }
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                    type="text"
                    className="form-control"
                    id="amount"
                    value={reqParams.amount}
                    readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select
                    name="status"
                    className="form-control float-right p-1"
                    value={reqParams.status}
                    onChange={handleChange}
                >
                  <option value="">------Select Status------</option>
                  <option value="3">Inquiry</option>
                  <option value="0">Success</option>
                  <option value="4">Reject</option>
                </select>

                <div className='clearfix' />
              </div>

              <div className="form-group">
                {image && <img id="target" src={image} alt='deposit-img' className='img-thumbnail' />}
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button
                  type="submit"
                  className="btn btn-primary float-right"
                  // disabled={reqParams.status === '0'}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Deposit Detail",
  location: '/panel/deposit/detail'
}

export default withHeader(pageSet)(DepositDetailPage)
