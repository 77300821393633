import React, { Component } from "react";

class Content extends Component {
  render() {
    return (
        <div className="content-wrapper">
          {this.props.children}
        </div>
    )
  }
}

export default Content;
