import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory, useParams } from "react-router";
import {fetchProductById, updateProduct} from "../../../services/products.service";
import {fetchCategory} from "../../../services/category.service";
import Select  from "react-select";
import { fetchBanks } from '../../../services/bank.service';

const ProductEditPage = () => {
  const [reqParams, setReqParams] = useState({
    kode: '',
    category_id: '',
    name: '',
    price: 0,
    biller_product: '',
    active : 0
  });

  let { id } = useParams();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [bankData, setBankData] = useState([]);

  useEffect((idParams = id) => {
    productData(idParams);
    categoriesData();
    banks();
  }, [id]);

  const productData = (id) => {
    fetchProductById(id).then(res => {
      console.log('id :>> ', id);
      let data = res.data.data;
      data.kode = res.data.data.kode ? res.data.data.kode : '';
      data.name = res.data.data.name ? res.data.data.name : '';
      data.category_id = res.data.data.category_id ? res.data.data.category_id : '';
      delete data._id;
      delete data.__v;
      delete data.productData;
      setReqParams(data)
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }

  const categoriesData = () => {
    fetchCategory().then(res => {
      setCategories(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const CategoriesOptions = categories.map(value => {
    return {value: value.code, label: value.name, name: 'category_id', key:value._id}
  })
  const BankOptions = bankData.map(value => {
    return {value: value.kode, label: value.name, name: 'bank_code', key:value._id}
  })
  const ActiveStatus = [
    { value: 0, label: 'Closed', name: 'active' },
    { value: 1, label: 'Active', name: 'active' },
    { value: 2, label: 'Product gangguan', name: 'active' },
  ];
  const ActiveSelectComponent = () => (
    <Select
        {...ActiveStatus}
        options={ActiveStatus}
        value={
          ActiveStatus.filter(option =>
          option.value === reqParams.active)
        }
        placeholder='Select Status'
        id="active"
        name='active'
        onChange={handleChangeProd}
        required
    />
)
const banks = () => {
  fetchBanks().then(res => {
    setBankData(res.data.data);
  }, err => {
    alert('Error load data');
    console.log('err', err.response);
  });
}

  const CategoriesSelectComponent = () => (
      <Select
          {...CategoriesOptions}
          options={CategoriesOptions}
          placeholder='select Category'
          value={
            CategoriesOptions.filter(option =>
                option.value === reqParams.category_id)
          }
          id="category_id"
          name='category_id'
          onChange={handleChangeProd}
          required
      />
  )

  const BanksSelectComponent = () => (
    <Select
        {...BankOptions}
        options={BankOptions}
        placeholder='select Category'
        value={
          BankOptions.filter(option =>
              option.value === reqParams.bank_code)
        }
        id="bank_code"
        name='bank_code'
        onChange={handleChangeProd}
        required
    />
)

  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
  }

  const handleChangeProd = (event) => {
    // console.log("changee", event)
    const params = {...reqParams}
    params[event.name] = event.value;
    setReqParams(params);
    // console.log(params)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('reqParams', reqParams);
    console.log('id', id);

    // return false;
    try {
      reqParams.price = Number(reqParams.price);
      reqParams.admin_fee = Number(reqParams?.admin_fee || 0);
      let result = await updateProduct(id, reqParams);
      console.log(result);
      if (result.status === 200 && result.data.message === 'updated') {
        alert('Success Update Product');
        history.push('/panel/products');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
    }
  }

  const handleBack = () => {
    // console.log(123);
    history.push('/panel/products');
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="cat-code">Category Code</label>
                {CategoriesSelectComponent()}
                {/*<select
                    className="w-100 p-2"
                    id="cat-code"
                    value={reqParams.category_id}
                    name='category_id'
                    onChange={handleChange}
                    required
                >
                  <option>-----Select Merchant-----</option>
                  {categories.map(val => {
                    return (<option value={val.code} key={val._id}>{val.name}</option>);
                  })}
                </select>*/}
              </div>
              <div className="form-group">
                <label htmlFor="kode">Kode</label>
                <input
                    type="text"
                    className="form-control"
                    id="kode"
                    placeholder="Enter kode"
                    value={reqParams.kode}
                    name='kode'
                    onChange={handleChange}
                    required />
              </div>
              <div className="form-group">
                <label htmlFor="name-banks">Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="name-banks"
                    name='name'
                    value={reqParams.name}
                    onChange={handleChange}
                    placeholder="Enter name"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="biller_product">Biller Product</label>
                <input
                    type="text"
                    className="form-control"
                    id="biller_product"
                    name='biller_product'
                    value={reqParams.biller_product}
                    onChange={handleChange}
                    placeholder="Enter biller_product"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="kode">Bank Code</label>
                {BanksSelectComponent()}
              
              </div>
              <div className="form-group">
                <label htmlFor="price">Price</label>
                <input
                    type="text"
                    className="form-control"
                    id="price"
                    name='price'
                    value={reqParams.price}
                    onChange={handleChange}
                    placeholder="Enter price"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="admin_fee">Admin Fee</label>
                <input
                    type="text"
                    className="form-control"
                    id="admin_fee"
                    name='admin_fee'
                    value={reqParams.admin_fee}
                    onChange={handleChange}
                    placeholder="Enter admin_fee"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="active">Select Status</label>
                {ActiveSelectComponent()}
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Update Product",
  location: '/panel/products/edit/id'
}

export default withHeader(pageSet)(ProductEditPage)
