import {
  USER_LOGIN_ATTEMPT,
  USER_LOGIN_ATTEMPT_FAILURE,
  USER_LOGIN_ATTEMPT_SUCCESS,
  USER_LOGOUT_SUCCESS
} from "./UserType"

const initState = {
  token: localStorage.getItem('uT') || null,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
  loadingLogin: false,
  errors: {}
}

const reducer = (state= initState, action) => {
  switch(action.type) {
    case USER_LOGIN_ATTEMPT:
      return {
        ...state,
        loadingLogin: true
      }
    case USER_LOGIN_ATTEMPT_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        loadingLogin: false
      }
    case USER_LOGIN_ATTEMPT_FAILURE:
      return {
        ...state,
        errors: action.payload.error
      }
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        token: "",
        user: {}
      }
    default: return state;
  }
}

export default reducer;
