import {Delete, Get, Post, Put} from "./config";

// Bank Endpoint
export const storeMerchantProduct = (params) => Post('merchant-prod', params);
export const updateMerchantProduct = (id, params) => Put('merchant-prod', id, params);
export const fetchMerchantsProduct = (params) => Get( 'merchant-prod?' + (params === undefined ? '' : params));
export const fetchMerchantProductById = (id) => Get( 'merchant-prod/' + id);
export const fetchMerchantProductByMerchantCode = (merchant_code) => Get( 'merchant-prod/search-by/' + merchant_code);
export const deleteMerchantProductById = (id, params) => Delete( 'merchant-prod/' + id, params);
export const removeMechantProductFromMerchant = (params) => Post( 'merchant-prod/remove-from-merchant', params);


