import React from 'react';
// import { Card } from 'react-bootstrap';

const AuthLayout = (props) => {
  return(
      <div className="auth-body">
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-md-12">
              {props.children}
            </div>
          </div>
        </div>
      </div>
  )
}

export default AuthLayout;
