import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {useHistory} from "react-router";
import {storeMerchantProduct} from "../../../services/merchant-product.service";
import {fetchProduct} from "../../../services/products.service";
import {fetchMerchants} from "../../../services/merchant.service";
import { fetchBanks } from '../../../services/bank.service';
import Select  from "react-select";

const MerchantProdNewPage = () => {
  const [reqParams, setReqParams] = useState({
    merchant_code: '',
    product_code: '',
    bank_code: '',
    fee_merchant: 0,
    percent_price: 0,
    fee_biller: 0,
    fee_kilat: 0,
  });
  const [bankData, setBankData] = useState([]);
  const [prodData, setProdData] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    products();
    merchants();
    banks();
  }, []);

  const banks = () => {
    fetchBanks().then(res => {
      setBankData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }
  const BankOptions = bankData.map(value => {
    return {value: value.kode, label: value.name, name: 'bank_code', key:value._id}
  })
  const BankSelectComponent = () => (
    <Select options={BankOptions} placeholder='select Bank' id="bank" name='bank_code' onChange={handleChangeProd} required />
)
  const products = () => {
    fetchProduct().then(res => {
      setProdData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const MerchantOptions = merchantData.map(value => {
    return {value: value.kode, label: value.name, name: 'merchant_code', key:value._id}
  })

  const MerchantSelectComponent = () => (
      <Select options={MerchantOptions} placeholder='select Merchant' id="merchant_code" name='merchant_code' onChange={handleChangeProd} required />
  )

  const ProdCodeOptions = prodData.map(value => {
      return {value: value.kode, label: value.name, name: 'product_code', key:value._id}
    })

  const ProductCodeSelectComponent = () => (
      <Select options={ProdCodeOptions} placeholder='select Product'  id="product_code" name='product_code' onChange={handleChangeProd} required />
  )

  const merchants = () => {
    fetchMerchants().then(res => {
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const handleChange = (event) => {
    const params = {...reqParams}
    // console.log("tg", event)
    params[event.target.name] = event.target.value;
    setReqParams(params);
    // console.log(params)
  }

  const handleChangeProd = (event) => {
    // console.log("changee", event)
    const params = {...reqParams}
    params[event.name] = event.value;
    setReqParams(params);
    // console.log(params)
  }

  const handleBack = () => {
    history.push('/panel/merchant-prods');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      reqParams.fee_merchant = Number(reqParams.fee_merchant);
      reqParams.percent_price = Number(reqParams.percent_price);
      reqParams.fee_kilat = Number(reqParams.fee_kilat);
      reqParams.fee_biller = Number(reqParams.fee_biller);
      
      let result = await storeMerchantProduct(reqParams);
     
      if (result.status === 201 && result.data.message === 'created') {
        alert('Success Create Merchant Product');
        history.push('/panel/merchant-prods');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
      return;
    }

  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="merchant_code">merchant_code</label>
                {MerchantSelectComponent()}
                {/*<select
                    className="w-100 p-2"
                    id="merchant_code"
                    value={reqParams.merchant_code}
                    name='merchant_code'
                    onChange={handleChange}
                    required>
                  <option>-----Select Merchant-----</option>
                  {merchantData.map(val => {
                    return (<option value={val.kode} key={val._id}>{val.name}</option>);
                  })}
                </select>*/}
              </div>
              {/*<div className="form-group">
                <label htmlFor="product_code">product_code</label>
                <select
                    className="w-100 p-2 selectMerchant"
                    id="product_code"
                    name='product_code'
                    value={reqParams.product_code}
                    onChange={handleChange}
                    required>
                  <option>-----Select Product-----</option>
                  {prodData.map(val => {
                    return (<option value={val.kode} key={val._id}>{val.name}</option>);
                  })}
                </select>
              </div>*/}
              <div className="form-group">
                <label htmlFor="product_code">product_code</label>
                {ProductCodeSelectComponent()}
              </div>
              <div className="form-group">
                <label htmlFor="bank_code">bank_code</label>
                {/* <input
                    type="text"
                    className="form-control"
                    id="bank_code"
                    name='bank_code'
                    value={reqParams.bank_code}
                    onChange={handleChange}
                    placeholder="Enter bank_code"
                /> */
                BankSelectComponent()
                }
                
              </div>
              <div className="form-group">
                <label htmlFor="fee_kilat">fee_kilat</label>
                <input
                    type="text"
                    className="form-control"
                    id="fee_kilat"
                    name='fee_kilat'
                    placeholder="Enter fee_kilat"
                    value={reqParams.fee_kilat}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="fee_biller">fee_biller</label>
                <input
                    type="text"
                    className="form-control"
                    id="fee_biller"
                    name='fee_biller'
                    placeholder="Enter fee_biller"
                    value={reqParams.fee_biller}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="fee_merchant">fee_merchant</label>
                <input
                    type="text"
                    className="form-control"
                    id="fee_merchant"
                    name='fee_merchant'
                    placeholder="Enter fee_merchant"
                    value={reqParams.fee_merchant}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="percent_price">percent_price</label>
                <input
                    type="text"
                    className="form-control"
                    id="percent_price"
                    name='percent_price'
                    placeholder="Enter percent_price"
                    value={reqParams.percent_price}
                    onChange={handleChange}
                    required
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Create Merchant Product",
  location: '/panel/merchant-prods/new'
}

export default withHeader(pageSet)(MerchantProdNewPage)
