// import MaterialIcon from '@material/react-material-icon';
import React from 'react';
import Content from "../panel/Content";
import {Link} from "react-router-dom";
// import { Link } from 'react-router-dom';


const withHeader = ({...matchProps}) => (WrappedComponent) => {
  // console.log(matchProps)
  return class extends React.Component {
    render() {
      return (
            <Content>
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>{matchProps.title}</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">{matchProps.title}</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">{matchProps.title}</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                        <i className="fas fa-minus"/>
                      </button>
                      <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                        <i className="fas fa-times"/>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <WrappedComponent />
                  </div>
                  {/*<div className="card-footer">*/}
                  {/*  Footer*/}
                  {/*</div>*/}
                </div>

              </section>
            </Content>
      )
    }
  }
}

export default withHeader;
