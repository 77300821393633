import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {storeUser} from "../../../services/panel.service";
import {useHistory} from "react-router";
import {roles} from "../../../assets/static/data";
import Select from 'react-select';
import { fetchMerchants } from '../../../services/merchant.service';
// import {fetchUsers} from "../../../services/panel.service";

const UserNewPage = (props) => {
  const [reqParams, setReqParams] = useState({
    full_name: '',
    email: '',
    phone_number: '',
    identity_id: '',
    active: 1,
    password: '',
    role: {},
    bank_code: '',
    username: '',
  });
  const [merchantData, setMerchantData] = useState([]);
  const [confirmPass, setConfirmPass] = useState('');
  const history = useHistory();
  useEffect(() => {
   
    merchants();
  }, [])

  const merchants = () => {
    fetchMerchants().then(res => {
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
    // console.log(params);
  }

  const handleChange2 = (event) => {
    const params = {...reqParams}
    params.role = event;
    setReqParams(params);
    // console.log(params);
  }

  const handleChangeBankCode = (event) => {
    const params = {...reqParams}
    params.bank_code = event.value;
    setReqParams(params);
    // console.log(params);
  }

  const handleConfirmPassChange = (event) => {
    setConfirmPass(event.target.value);
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    if (confirmPass !== reqParams.password) {
      alert('password should same with confirm password!');
      return;
    }

    try {
      let result = await storeUser(reqParams);
      console.log(result);
      if (result.status === 201 && result.data.message === 'created') {
        alert('Success Create User');
        history.push('/panel/users');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
      return;
    }

    console.log(confirmPass)
    console.log('reqParams', reqParams);
  }
  const MerchantOptions = merchantData.map(value => {
    return {value: value.kode, label: value.name, name: 'bank_code', key:value._id}
  })
  const BankSelectComponent = () => (
    <Select options={MerchantOptions} placeholder='select Ca' id="bank" name='bank_code' onChange={handleChangeBankCode} required />
    )
  const selectRole = () => {
    const options = roles().map(val => {
      return {
        value: val.id,
        label: val.name
      }
    })

    return (
        <Select
            value={options.value}
            options={options}
            placeholder='select role'
            onChange={handleChange2}
        />
    )
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleLogin}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    placeholder="Enter full name"
                    value={reqParams.full_name}
                    name='full_name'
                    onChange={handleChange}
                    required />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name='email'
                    value={reqParams.email}
                    onChange={handleChange}
                    placeholder="Enter email"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name='phone_number'
                    value={reqParams.phone_number}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="identity">Identity ID</label>
                <input
                    type="text"
                    className="form-control"
                    id="identity"
                    name='identity_id'
                    placeholder="Enter identity"
                    value={reqParams.identity_id}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="identity">Username</label>
                <input
                    type="text"
                    className="form-control"
                    id="username"
                    name='username'
                    placeholder="Enter username"
                    value={reqParams.username}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="identity">Role</label>
                {selectRole()}
              </div>
              <div className="form-group">
                <label htmlFor="bank_code">Bank Code</label>
                {/* <input
                    type="text"
                    className="form-control"
                    id="bank_code"
                    name='bank_code'
                    placeholder="Enter bank_code"
                    value={reqParams.bank_code}
                    onChange={handleChange}
                    required
                /> */}
                  
                {BankSelectComponent()}
              
             
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    name='password'
                    placeholder="Password"
                    value={reqParams.password}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Password"
                    value={confirmPass}
                    onChange={handleConfirmPassChange}
                    required
                />
              </div>
            </div>

            <div className="card-footer">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Create Users",
  location: '/panel/users/new'
}

export default withHeader(pageSet)(UserNewPage)
