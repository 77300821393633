import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import moment from "moment";
import {fetchTrxCount} from "../../../services/trx.service";
import {currentUser} from "../../../services/panel.service";
import {fetchMerchantCode} from "../../../services/merchant.service";

const DashboardCaPage = () => {
  const [trxDataY, setTrxDataY] = useState(0);
  const [trxDataM, setTrxDataM] = useState(0);
  const [trxDataD, setTrxDataD] = useState(0);
  const [userBank, setUserBank] = useState('001');
  const [merchanData, setMerchanData] = useState('0');

  useEffect(() => {
    const fetchData = async (bankUser = userBank) => {
      const result = await getCurrentUser();
      setUserBank(result.bank_code);
      const dataY = await trxHistDataYear(bankUser);
      setTrxDataY(dataY);
      const dataM = await trxHistDataMonth(bankUser);
      setTrxDataM(dataM);
      const dataD = await trxHistDataDay(bankUser);
      setTrxDataD(dataD);
      getMerchantCode(bankUser);
    };
    fetchData();
  }, [userBank]);

  const trxHistDataYear = async (val, yearD) => {
    let paramsReq = {
      from_date: moment().format('YYYY-01-01'),
      to_date: moment().format('YYYY-MM-DD') + ' 23:59:59',
      merchant_id: '',
      status: '0',
      type: 'ca'
    };
    paramsReq.merchant_id = val

    try {
      let res = await fetchTrxCount(paramsReq);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      console.log('err', e.response);
    }
  }

  const trxHistDataMonth = async (val) => {
    let paramsReq = {
      from_date: moment().format('YYYY-MM-01'),
      to_date: moment().format('YYYY-MM-DD') + ' 23:59:59',
      merchant_id: '',
      status: '0',
      type: 'ca'
    };
    paramsReq.merchant_id = val;

    try {
      let res = await fetchTrxCount(paramsReq);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      console.log('err', e.response);
    }
  }

  const trxHistDataDay = async (val) => {
    let paramsReq = {
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD') + ' 23:59:59',
      merchant_id: '',
      status: '0',
      type: 'ca'
    };
    paramsReq.merchant_id = val;

    try {
      let res = await fetchTrxCount(paramsReq);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      console.log('err', e.response);
    }
  }

  const getMerchantCode = (val) => {
    const code = val;
    if (val !== '001') {
      fetchMerchantCode({code}).then(res => {
        setMerchanData(res.data.data.current_balance);
      }).catch(e => {
        alert('Error load data');
        console.log('err', e.response);
      });
    }
  }

  const getCurrentUser = async () => {
    try {
      const result = await currentUser();
      return result.data.data;
    } catch (e) {
      alert('Error load data');
      console.log('err', e.response);
    }
  }

  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }

    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
        <div className="row">
          <div className="col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{ currencyFormat(trxDataY) }</h3>

                <p>Transaction this year</p>
              </div>
              <div className="icon">
                <i className="ion ion-bag"/>
              </div>
              <span className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
          <div className="col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{ currencyFormat(trxDataM) }</h3>

                <p>Transaction this month</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars"/>
              </div>
              <span className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
          <div className="col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{currencyFormat(trxDataD)}</h3>

                <p>Transaction this day</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add"/>
              </div>
              <span href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
          <div className="col-6">
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{ currencyFormat(merchanData) }</h3>

                <p>Current Balance</p>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph"/>
              </div>
              <span href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
        </div>
  );
}

let pageSet = {
  title: "Dashboard"
}

export default withHeader(pageSet)(DashboardCaPage)
