import React, {useEffect, useState} from 'react';
import withHeader from '../hoc/withHeader';
import moment from "moment";
import {fetchTrxCount} from "../../services/trx.service";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

const DashboardPage = () => {
  const userData = useSelector(state => state.user.user);
  const history = useHistory();
  const [trxDataY, setTrxDataY] = useState(0);
  const [trxDataM, setTrxDataM] = useState(0);
  const [trxDataD, setTrxDataD] = useState(0);

  const year = () => {
    return {
      from_date: moment().format('YYYY-01-01'),
      to_date: moment().format('YYYY-MM-DD'),
      status: '0',
    };
  };

  const month = () => {
    return {
      from_date: moment().format('YYYY-MM-01'),
      to_date: moment().format('YYYY-MM-DD'),
      status: '0',
    };
  };

  useEffect(() => {
    if (userData.role.label === 'ca') {
      history.push('/panel/dashboard-ca');
    }
    trxHistDataYear(year());
    trxHistDataMonth(month());
    trxHistDataDay();
  }, [userData, history]);

  const trxHistDataYear = async (searchParams) => {
    try {
      let res = await fetchTrxCount(searchParams);
      setTrxDataY(res.data.data);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      console.log('err', e.response);
    }
  }

  const trxHistDataMonth = async (searchParams) => {
    try {
      let res = await fetchTrxCount(searchParams);
      setTrxDataM(res.data.data);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      console.log('err', e.response);
    }
  }

  const trxHistDataDay = async (val) => {
    let paramsReq = {
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD') + ' 23:59:59',
      status: '0',
    };
    paramsReq.merchant_id = val;

    // try {
    //   let res = await fetchTrx(paramsReq);
    //   setTrxDataD(res.data.data.length);
    //   return res.data.data.length;
    // } catch (e) {
    //   alert('Error load data');
    //   console.log('err', e.response);
    // }

    try {
      let res = await fetchTrxCount(paramsReq);
      setTrxDataD(res.data.data);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      console.log('err', e.response);
    }
  }

  // const currencyFormat = (num) => {
  //   return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  // }

  const currencyFormat = (num) => {
    if (!num) {
      return '0';
    }

    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{ currencyFormat(trxDataY) }</h3>

                <p>Transaksi Tahun Ini</p>
              </div>
              <div className="icon">
                <i className="ion ion-bag"/>
              </div>
              <span className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{ currencyFormat(trxDataM) }</h3>

                <p>Transaksi Bulan Ini</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars"/>
              </div>
              <span className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{ currencyFormat(trxDataD) }</h3>

                <p>Transaksi Hari Ini</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add"/>
              </div>
              <span href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>65</h3>

                <p>Total Biller</p>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph"/>
              </div>
              <span href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"/></span>
            </div>
          </div>
        </div>
  );
}

let pageSet = {
  title: "Dashboard"
}

export default withHeader(pageSet)(DashboardPage)
