import axios from "axios";
// import store from '../redux/store';


// Set default DSN from .env file
export const API_DSN = process.env.REACT_APP_API_BASE_URL;

// Setting header axios
export const axiosHeader = () => {
  // Set axios interceptors for every request from axios
  axios.interceptors.request.use( (requestConfig) => {
    // Check is request from Default DSN (Domain Service Name)
    if(requestConfig.url.indexOf(API_DSN) > -1) {
      // Store token data
      const token =  localStorage.getItem('uT');
      // console.log(token);
      // check if token exsits
      if(token) {
        // add authorization barear
        requestConfig.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    // returning request config for axios request
    return requestConfig;
  });
}

// Set config for get request
export const Get = (path, params) => {
  return new Promise((resolve, reject) => {
    axiosHeader()
    axios.get(`${API_DSN}/${path}`, {
      params: params
    }).then(res => {
      return resolve(res)
    }, err => {
      return reject(err)
    })
  });
}

// Set config for post request
export const Post = (path, data) => {
  return new Promise((resolve, reject) => {
    axiosHeader()
    axios.post(`${API_DSN}/${path}`, data).then(res => {
      return resolve(res)
    }, err => {
      console.log(err)
      if (err.message === 'Network Error') {
      }
      return reject(err)
    })
  });
}

// Set config for put request
export const Put = (path, id, data) => {
  return new Promise((resolve, reject) => {
    axiosHeader()
    axios.put(`${API_DSN}/${path}/${id}`, data).then(res => {
      return resolve(res)
    }, err => {
      console.log(err)
      if (err.message === 'Network Error') {
      }
      return reject(err)
    })
  });
}

// Set config for delete request
export const Delete = (path, data) => {
  return new Promise((resolve, reject) => {
    axiosHeader()
    axios.delete(`${API_DSN}/${path}`, data).then(res => {
      return resolve(res)
    }, err => {
      return reject(err)
    })
  });
}

export const ProxyReq = (method, path, data) => {
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_PROXY_URL, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("uT")}`,
        'Content-Type': 'application/json',
        'request-url': path,
        'request-username': 'ariandin',
        'request-method': method || 'GET',
      }
    }).then(res => {
      return resolve(res);
    }, err => {
      return reject(err);
    });
  });
}
