import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory, useParams } from "react-router";
import {fetchMerchantById, updateMerchant} from "../../../services/merchant.service";
import Select  from "react-select";

const MerchantEditPage = () => {
  const [reqParams, setReqParams] = useState({
    kode: '',
    name: '',
    user: '',
    type_payment: '',
    password: '',
    secret_code: '',
    callback_url: '',
    current_balance: 0,
    bank_account: '',
    bank_name: '',
    bank_branch: '',
  });

  let { id } = useParams();
  const history = useHistory();

  useEffect((idParams = id) => {
    merchantData(idParams);
  }, [id]);

  const merchantData = (id) => {
    fetchMerchantById(id).then(res => {
      // console.log('res', res.data)
      let data = res.data.data;
      data.kode = res.data.data.kode ? res.data.data.kode : '';
      data.name = res.data.data.name ? res.data.data.name : '';
      data.user = res.data.data.user ? res.data.data.user : '';
      data.password = res.data.data.password ? res.data.data.password : '';
      data.secret_code = res.data.data.secret_code ? res.data.data.secret_code : '';
      data.callback_url = res.data.data.callback_url ? res.data.data.callback_url : '';
      data.current_balance = res.data.data.current_balance ? res.data.data.current_balance : 0;
      data.bank_account = res.data.data.bank_account ? res.data.data.bank_account : '';
      data.bank_name = res.data.data.bank_name ? res.data.data.bank_name : '';
      data.bank_branch = res.data.data.bank_branch ? res.data.data.bank_branch : '';
      delete data._id;
      delete data.__v;
      delete data.merchantData;
      setReqParams(data)
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }

  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
  }

  const handleChangeType = (event) => {
    const params = {...reqParams}
    params[event.name] = event.value;
    setReqParams(params);
  }
  const MerchantTypes = [
    {value : '1',  label : 'Settlement', name:'type_payment'},
    {value : '0',  label : 'Deposite', name : 'type_payment'},
  ];
  const MerchantTypeSelectComponent = () => (
    <Select
        {...MerchantTypes}
        options={MerchantTypes}
        value={
          MerchantTypes.filter(option =>
          option.value === reqParams.type_payment)
        }
        placeholder='select Merchant'
        id="merchant_code"
        name='merchant_code'
        onChange={handleChangeType}
        required
    />
)
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('reqParams', reqParams);
    console.log('id', id);

    // return false;
    try {
      reqParams.current_balance = Number(reqParams.current_balance);
      let result = await updateMerchant(id, reqParams);
      // console.log(result);
      if (result.status === 200 && result.data.message === 'updated') {
        alert('Success Update Merchant');
        history.push('/panel/merchants');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
    }
  }

  const handleBack = () => {
    // console.log(123);
    history.push('/panel/merchants');
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="kode">Kode</label>
                <input
                    type="text"
                    className="form-control"
                    id="kode"
                    placeholder="Enter kode"
                    value={reqParams.kode}
                    name='kode'
                    onChange={handleChange}
                    required />
              </div>
              <div className="form-group">
                <label htmlFor="type_payment">Type Payment</label>
                {MerchantTypeSelectComponent()}
  
              </div>
              <div className="form-group">
                <label htmlFor="name-banks">Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="name-banks"
                    name='name'
                    value={reqParams.name}
                    onChange={handleChange}
                    placeholder="Enter name"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="user-bank">User</label>
                <input
                    type="text"
                    className="form-control"
                    id="user-bank"
                    name='user'
                    value={reqParams.user}
                    onChange={handleChange}
                    placeholder="Enter user"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password-bank">Password</label>
                <input
                    type="text"
                    className="form-control"
                    id="password-bank"
                    name='password'
                    placeholder="Enter password"
                    value={reqParams.password}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="secret-code">Secret Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="secret-code"
                    name='secret_code'
                    placeholder="Enter secret_code"
                    value={reqParams.secret_code}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="callback_url">Callback Url</label>
                <input
                    type="text"
                    className="form-control"
                    id="callback_url"
                    name='callback_url'
                    placeholder="Enter callback_url"
                    value={reqParams.callback_url}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cur_balance_merchant">Balance</label>
                <input
                    type="text"
                    className="form-control"
                    id="cur_balance_merchant"
                    placeholder="Enter base First Balance"
                    name="current_balance"
                    value={reqParams.current_balance}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="bank_account_merchant">Bank Account</label>
                <input
                    type="text"
                    className="form-control"
                    id="bank_account_merchant"
                    placeholder="Enter Bank Account"
                    name='bank_account'
                    value={reqParams.bank_account}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="bank_name_merchant">Bank Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="bank_name_merchant"
                    placeholder="Enter Bank Name"
                    name='bank_name'
                    value={reqParams.bank_name}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="bank_branch_merchant">Bank Branch</label>
                <input
                    type="text"
                    className="form-control"
                    id="bank_branch_merchant"
                    placeholder="Enter Bank Branch"
                    name='bank_branch'
                    value={reqParams.bank_branch}
                    onChange={handleChange}
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Update Merchant",
  location: '/panel/merchants/edit/id'
}

export default withHeader(pageSet)(MerchantEditPage)
