import React, {useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {useHistory} from "react-router";
import {storeDeposit} from "../../../services/trx.service";
// import {storeMerchant} from "../../../services/merchant.service";

const TrxCaDepositNewPage = () => {
  const [reqParams, setReqParams] = useState({
    amount: 0,
    image: '',
  });
  const [image, setImage] = useState('');

  const history = useHistory();

  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
    // console.log(params)
  }

  const handleBack = () => {
    history.push('/panel/trx-ca-deposit');
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.loaded)
        if (e.loaded > 2007060) {
          alert('image size max 2MB')
          return;
        }
        setImage(e.target.result);
        const params = {...reqParams}
        params.image = e.target.result;
        setReqParams(params);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let result = await storeDeposit(reqParams);
      console.log(result);
      if (result.status === 201 && result.data.message === 'created') {
        alert('Success Create Deposit');
        history.push('/panel/trx-ca-deposit');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
      return;
    }
    console.log('reqParams', reqParams);
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                    type="text"
                    className="form-control"
                    id="amount"
                    placeholder="Enter amount"
                    value={reqParams.amount}
                    name='amount'
                    onChange={handleChange}
                    required />
              </div>
              <div className="form-group">
                <label htmlFor="file">File</label>
                <input
                    type="file"
                    className="form-control"
                    id="file"
                    name='file'
                    value={reqParams.file}
                    onChange={onImageChange}
                    required
                />
              </div>
              <div className="form-group">
                {image && <img id="target" src={image} alt='deposit-img' className='img-thumbnail' />}
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back udah
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Create Deposit",
  location: '/panel/trx-ca/deposit/new'
}

export default withHeader(pageSet)(TrxCaDepositNewPage)
