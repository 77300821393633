import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {useHistory} from "react-router";
import {deleteMerchantById, fetchMerchants} from "../../../services/merchant.service";
import {useSelector} from "react-redux";

const MerchantPage = () => {
  const userData = useSelector(state => state.user.user);
  const [merchantData, setMerchantData] = useState([]);
  const [merchantDataTmp, setMerchantDataTmp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (userData.role.label === 'ca') {
      history.push('/panel/dashboard-ca');
    }
    merchantsData();
  }, [userData, history]);

  const merchantsData = () => {
    setLoading(true);
    fetchMerchants().then(res => {
      setMerchantDataTmp(res.data.data);
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const handleChange = async (event) => {
    await setSearch(event.target.value)
    let searchRes = merchantDataTmp;

    if (!event.target.value) {
      setMerchantData(merchantDataTmp);
      return;
    }

    const searchRes2 = searchRes.filter((val) => {
      return val.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
          val.kode.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
    });
    setMerchantData(searchRes2);
  }

  const deleteHandler = (id) => {
    // console.log(id);
    // eslint-disable-next-line no-restricted-globals
    const r = confirm("are you sure to delete this data?");
    if (!r) {
      return;
    }
    deleteMerchantById(id, {}).then(res => {
      console.log(res);
      alert('delete data success');
      merchantsData();
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const createNewMerchantHandler = () => {
    console.log('new');
    history.push('/panel/merchants/new');
  }

  const updateBankHandler = (id) => {
    console.log('new');
    history.push('/panel/merchants/edit/' + id);
  }

  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }
    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const loopBank = () => {
    return merchantData.map((val, i) => {
     var typePayment = '';
      if(val.type_payment === '0'){
       typePayment = 'Deposite';
      }else if (val.type_payment === '1'){
       typePayment = 'Settlement';
      }else{
       typePayment = 'No Selected'
      }
      
      return (
          <tr key={i}>
            <td>{ val._id }</td>
            <td>{ val.kode }</td>
            <td>{ val.name }</td>
            <td>{ val.user }</td>
            <td>{ val.password }</td>
            <td>{ val.secret_code }</td>
            <td>{ val.callback_url }</td>
            <td>{typePayment }</td>
            <td className='text-right'>{ currencyFormat(val.current_balance) }</td>
            <td>{ val.bank_account }</td>
            <td>{ val.bank_name }</td>
            <td>{ val.bank_branch }</td>
            <td>
              <span className="badge bg-danger mr-1" onClick={() => { deleteHandler(val._id)}}>
                <i className="fas fa-trash-alt p-1" aria-hidden="true" />
              </span>

              <span className="badge bg-success mr-1" onClick={() => {updateBankHandler(val._id)}}>
                <i className="fas fa-pencil-alt p-1" aria-hidden="true" />
              </span>
            </td>
          </tr>
      );
    })
  }

  return (
      <div className="row">
        <div className="col-8">
          <input
              type="text"
              name="table_search"
              className="form-control float-right p-1"
              value={search}
              onChange={handleChange}
              placeholder="Search" />
        </div>
        <div className="col-4">
          <button type="button" className="btn btn-block btn-primary" onClick={() => {createNewMerchantHandler()}}>
            <i className='fas fa-plus' aria-hidden='true' />
          </button>
        </div>
        <div className="col-12">
          <hr className='hr-user' />
        </div>
        <div className="col-12">
          <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>ID</th>
                <th>Kode</th>
                <th>Name</th>
                <th>User</th>
                <th>Password</th>
                <th>Secret Code</th>
                <th>Callback Url</th>
                <th>Type Payment</th>
                <th className='text-right'>Balance</th>
                <th>Bank Account</th>
                <th>Bank Name</th>
                <th>Bank Branch</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              { loading && <tr><td colSpan='6'>Load Data</td></tr> }
              { (!loading && merchantData.length > 0) && loopBank() }
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Merchant",
  location: '/panel/merchants'
}

export default withHeader(pageSet)(MerchantPage)
