import React, { useState } from 'react';
import {Card, Form, InputGroup, Alert, Container, Row, Col} from 'react-bootstrap';
import { CButton } from '../../components/FormComponent';
import LogoImg from '../../assets/img/kilatlogo.jpeg';
import { attemptLogin } from '../../services/auth.service';
import MaterialIcon from '@material/react-material-icon';
import { withRouter } from 'react-router-dom';
import { attemptLoginSuccessAction } from '../../redux/user/UserAction';
import { compose } from 'redux';
import { connect } from 'react-redux';

const LoginPage = (props) => {

  const [userInfo, setUserInfo] = useState({email: '', password: ''});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [errMessage, setErrMessage] = useState('');

  const handleChange = (event) => {
    const newUserInfo = {...userInfo}
    newUserInfo[event.target.name] = event.target.value;
    setUserInfo(newUserInfo);
  }

  const closeAlert = () => {
    setAlert({});
  }

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    attemptLogin(userInfo).then(res => {
      recordUserData(res);
    }, err => {
      setAlert({
        show: true,
      });
      console.log('err.response', err);
      setErrMessage(err.response.data.message);
      setTimeout( () => {
        closeAlert();
        setErrMessage('');
      }, 5000);
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const recordUserData = (user) => {
    user = user.data;
    const userData = {
      fullName: user.data.full_name,
      email: user.data.email,
      _id: user.data._id,
      role: user.data.role ? JSON.parse(user.data.role) : {},
      bank_code: user.data.bank_code ? user.data.bank_code : '-'
    }
    // console.log(user.token);
    // console.log(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('uT', user.token);
    props.loginSuccess({
      token: user.token,
      user: userData
    });

    if (Number(userData.role.value) === 1) {
      props.history.push('/panel/dashboard');
    } else {
      props.history.push('/panel/dashboard-ca');
    }
  }


  return (
      <div>
        <Container className="mt-4">
          <Row className="justify-content-md-center">
            <Col md="12" lg="6">
              <Card
                  className="auth-card border-0 shadow-sm"
              >
                <Card.Body>
                  <Alert
                      variant="danger"
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        zIndex: 999
                      }}
                      dismissible
                      show={!!alert?.show}
                      onClose={ () => closeAlert() }
                  >
                    { errMessage }
                  </Alert>
                  <div className="row no-gutters">
                    <div className="col-md-12 pb-5 pt-4 px-3">
                      <div className="text-center d-block">
                        <img
                            className="embed-responsive logo-img"
                            src={LogoImg}
                            alt="logo" />
                      </div>
                      <p className="mb-2 text-dark small font-weight-bold mt-5">Log in to your account</p>
                      <Form onSubmit={handleLogin}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <MaterialIcon icon="person_outline" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                              placeholder="Email"
                              name="email"
                              type="email"
                              value={userInfo.username}
                              onChange={handleChange}
                              required
                          />
                        </InputGroup>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <MaterialIcon icon="lock" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                              placeholder="Password"
                              name="password"
                              type="password"
                              value={userInfo.password}
                              onChange={handleChange}
                              required
                          />
                        </InputGroup>
                        <CButton
                            type="submit"
                            className="mt-4 px-4 btn-info w-100"
                            onClick={handleLogin}
                            loading={loading}
                            disabled={!(userInfo?.email && userInfo?.password)}
                        >
                          Log in
                        </CButton>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
  );
}

const mapDispatchToProps = dispatch => ({
  loginSuccess: params => dispatch(attemptLoginSuccessAction(params))
})

export default compose(
    connect(
        null,
        mapDispatchToProps
    ),
    withRouter
)(LoginPage);
