import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory, useParams } from "react-router";
import {fetchCategoryById, updateCategory} from "../../../services/category.service";

const CategoryEditPage = () => {
  const [reqParams, setReqParams] = useState({
    code: '',
    name: '',
  });

  let { id } = useParams();
  const history = useHistory();

  useEffect((idParams = id) => {
    categoryData(idParams);
  }, [id]);

  const categoryData = (id) => {
    fetchCategoryById(id).then(res => {
      // console.log('res', res.data)
      let data = res.data.data;
      data.code = res.data.data.code ? res.data.data.code : '';
      data.name = res.data.data.name ? res.data.data.name : '';
      delete data._id;
      delete data.__v;
      delete data.categoryData;
      setReqParams(data)
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }

  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('reqParams', reqParams);
    console.log('id', id);

    // return false;
    try {
      let result = await updateCategory(id, reqParams);
      console.log(result);
      if (result.status === 200 && result.data.message === 'updated') {
        alert('Success Update Category');
        history.push('/panel/categories');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
    }
  }

  const handleBack = () => {
    // console.log(123);
    history.push('/panel/categories');
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="code">Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="code"
                    placeholder="Enter Code"
                    value={reqParams.code}
                    name='code'
                    onChange={handleChange}
                    required />
              </div>
              <div className="form-group">
                <label htmlFor="name-banks">Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="name-banks"
                    name='name'
                    value={reqParams.name}
                    onChange={handleChange}
                    placeholder="Enter name"
                    required
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Update Category",
  location: '/panel/categories/edit/id'
}

export default withHeader(pageSet)(CategoryEditPage)
