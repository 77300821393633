import React, { Component } from 'react';
import { useSelector } from "react-redux";
import {sideMenu, sideMenuCA} from "../../assets/static/data";
import { Link } from "react-router-dom";

export const UserDataComponent = () => {
  const userData = useSelector(state => state.user.user);
  return <div>{userData.fullName}</div>
}

class AssideLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      reduxState: {}
    };
    // console.log(this.props.dataLocation)
    // console.log(this.props.userLoc)
  }

  render_test() {
    return <UserDataComponent />
  }

  render_side() {
    // console.log('aduh', this.props.dataLocation);
    const side = sideMenu().map(data => {
        return (
            <li className="nav-item" key={data.key}>
              <Link to={data.link} className="nav-link">
                <i className={`nav-icon ${data.icon}`}/>
                <p>
                  {data.name}
                  {/*<i className="right fas fa-angle-left"/>*/}
                </p>
              </Link>
            </li>
        )
    });
    return side;
  }

  render_side_ca() {
    // console.log('aduh', this.props.dataLocation);
    const side = sideMenuCA().map(data => {
      return (
          <li className="nav-item" key={data.key}>
            <Link to={data.link} className="nav-link">
              <i className={`nav-icon ${data.icon}`}/>
              <p>
                {data.name}
                {/*<i className="right fas fa-angle-left"/>*/}
              </p>
            </Link>
          </li>
      )
    });
    return side;
  }

  render_logout() {
    return (
        <li className="nav-item">
          <span className="nav-link" onClick={() => {this.handleLogout()}}>
            <i className={`nav-icon fas fa-power-off`}/>
            <p>
              Logout
              {/*<i className="right fas fa-angle-left"/>*/}
            </p>
          </span>
        </li>
    );
  }

  handleLogout = () => {
    localStorage.clear();
    window.location.href = '/'
  }

  render() {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <Link to={'/'} className="brand-link">
            <img src={'/dist/img/AdminLTELogo.png'} alt="AdminLTE Logo"
                 className="brand-image img-circle elevation-3" />
              <span className="brand-text font-weight-light">KILAT DIGITAL</span>
          </Link>

          <div className="sidebar">
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img src={'/dist/img/user2-160x160.jpg'} className="img-circle elevation-2" alt={'User'} />
              </div>
              <div className="info">
                <a href={'/panel/dashboard'} className="d-block">
                  {this.render_test()}
                </a>
              </div>
            </div>

            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                  data-accordion="false">
                {this.props.userLoc === 'admin' && this.render_side()}
                {this.props.userLoc === 'ca' && this.render_side_ca()}
                {this.render_logout()}
              </ul>
            </nav>
          </div>
        </aside>
    )
  }
}

export default AssideLayout;
