import { Button, Form } from "react-bootstrap"
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Fragment } from "react"

export const TextField = ({controlId, label, type, name, placeholder, controlSize, disabled, value, onChange, error}) => {
  return(
      <Form.Group
          controlId={controlId}
          className="mb-4"
      >
        { label &&
        <Form.Label>{ label }</Form.Label>
        }
        <Form.Control
            type={ type || 'text' }
            name={name}
            placeholder={placeholder}
            size={controlSize || 'md'}
            disabled={disabled || false}
            value={value || ''}
            onChange={onChange || ''}
            className={`${error?.length ? 'is-invalid' : ''}`}
        />
        { error &&
        <Form.Control.Feedback type="invalid">
          { error[0] }
        </Form.Control.Feedback>
        }
      </Form.Group>
  )
}

TextField.propTypes = {
  controlId: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string
}

export const SelectField = ({name, label, disabled, error, value, onChange, ...props}) => {
  return(
      <Form.Group controlId="selectForm.selectForm" className="mb-4">
        <Form.Label>{label}</Form.Label>
        <Form.Control
            as="select"
            name={name}
            disabled={disabled}
            custom
            className={`${error?.length ? 'is-invalid' : ''}`}
            onChange={onChange || ''}
            value={value || ''}
        >
          {props.children}
        </Form.Control>
        { error?.length &&
        <Form.Control.Feedback type="invalid">
          {error[0]}
        </Form.Control.Feedback>
        }
      </Form.Group>
  )
}



export const CButton = ({loading, disabled, ...props}) => {
  return(
      <Button
          disabled={disabled || loading}
          {...props}
      >
        { loading ?
            <Fragment>
              <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
            </Fragment>
            :
            props.children
        }
      </Button>
  )
}

CButton.propTypes = {

}
