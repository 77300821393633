import {Delete, Get, Post, Put} from "./config";

// Bank Endpoint
export const storeProduct = (params) => Post('products', params);
export const updateProduct = (id, params) => Put('products', id, params);
export const fetchProduct = (params) => Get( 'products', params);
export const fetchProductExcel = (params) => Get( 'products/export-excel', params);
export const fetchProductById = (id) => Get( 'products/' + id);
export const deleteProductById = (id, params) => Delete( 'products/' + id, params);
export const seacrchProductByParam = (params, page = 1, filter = '') => Post( 'products/search-by?page=' + page + '&filter=' + filter, params);
