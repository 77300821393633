import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory, useParams } from "react-router";
import {fetchTrxById} from "../../../services/trx.service";

const TrxCaDepositDetailPage = () => {
  const [reqParams, setReqParams] = useState({
    amount: 0,
    image: '',
    status: '',
  });

  const [image, setImage] = useState('');
  let { id } = useParams();
  const history = useHistory();

  useEffect((idParams = id) => {
    trxDepositData(idParams);
  }, [id]);

  const trxDepositData = (id) => {
    fetchTrxById(id).then(res => {
      // console.log('res', res.data)
      let data = res.data.data;
      data.amount = res.data.data.amount ? res.data.data.amount : '';
      data.image = res.data.data.tx_detail ? res.data.data.tx_detail : '';
      data.status = res.data.data.status ? res.data.data.status : '';

      if (data.status === '3') {
        data.status = 'Inquiry';
      } else if (data.status === '0') {
        data.status = 'Success';
      }
      setReqParams(data);
      setImage(data.image);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }

  const handleBack = () => {
    // console.log(123);
    history.push('/panel/trx-ca-deposit');
  }

  return (
      <div className="row">
        <div className="col-12">
          <form>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input
                    type="text"
                    className="form-control"
                    id="amount"
                    value={reqParams.amount}
                    readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <input
                    type="text"
                    className="form-control"
                    id="status"
                    value={reqParams.status}
                    readOnly
                />
              </div>
              <div className="form-group">
                {image && <img id="target" src={image} alt='deposit-img' className='img-thumbnail' />}
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Deposit Detail",
  location: '/panel/trx-ca-deposit/detail'
}

export default withHeader(pageSet)(TrxCaDepositDetailPage)
