import React, { useEffect, useState } from 'react';
// import LogoImg from '../../assets/img/logo.png';
// import AvaImg from '../../assets/img/ava.png';
// import { Dropdown } from 'react-bootstrap';
// import { Link, withRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// import MaterialIcon from '@material/react-material-icon';
import { compose } from 'redux';
import {connect, useSelector} from 'react-redux';
import { userLogoutSuccessAction } from '../../redux/user/UserAction';
import AssideLayout from "./AssideLayout";
import NavLayout from "./NavLayout";
import Footer from "./Footer";
// import { fetchRoles } from '../../services/panel.service';

const PanelLayout = (props) => {
  const userData = useSelector(state => state.user.user);
  const [roles, setRoles] = useState({});

  useEffect((dataUser = userData) => {
    getRoles(dataUser)
  }, [userData]);

  const getRoles = (dataUser) => {
    setRoles(dataUser.role.label);
    // fetchRoles().then(res => {
    //   // console.log(res.data.roles);
    //   setRoles(res.data.roles);
    // }, err => {
    //   console.log(err);
    // });
  }

  // const handleLogout = (e) => {
  //   e.preventDefault()
  //   localStorage.clear();
  //   props.logoutSuccess();
  //   props.history.push('/');
  // }

  return (
      <>
        <NavLayout />
        <AssideLayout
            dataLocation={props.location.pathname}
            userLoc={userData.role.label}
            history={props.history}
        />
          { roles?.length ?
              React.cloneElement(props.children, {roles: [...roles]})
              :
              ""
          }
        <Footer />
      </>
  );
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  logoutSuccess: () => dispatch(userLogoutSuccessAction())
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter
)(PanelLayout);
