import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import PanelLayout from '../views/layouts/PanelLayout';

const PanelRoute = ({component: Component, token, ...rest}) => {
  return(
      <Route
          {...rest}
          render={ matchProps => (
              <Fragment>
                { token ?
                    <PanelLayout {...rest}>
                      <Component {...matchProps} />
                    </PanelLayout>
                    :
                    <Redirect
                        to={{
                          pathname: "/"
                        }}
                    />
                }
              </Fragment>
          )}
      >
      </Route>
  );
}

const mapStateToProps = state => ({
  token: state.user.token
});

export default connect(
    mapStateToProps
)(PanelRoute);
