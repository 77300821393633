import { USER_LOGIN_ATTEMPT_SUCCESS, USER_LOGOUT_SUCCESS } from "./UserType"

// const attemptLogin = () => {
//     return {
//         type: USER_LOGIN_ATTEMPT
//     }
// }

const attemptLoginSuccess = (payload) => {
  return {
    type: USER_LOGIN_ATTEMPT_SUCCESS,
    payload: payload
  }
}

// const attemptLoginFailure = (payload) => {
//     return {
//         type: USER_LOGIN_ATTEMPT_FAILURE,
//         payload: payload
//     }
// }

const userLogoutSuccess = () => {
  return {
    type: USER_LOGOUT_SUCCESS
  }
}

export const attemptLoginSuccessAction = (res) => {
  return dispatch => {
    dispatch(attemptLoginSuccess(res));
  }
}

export const userLogoutSuccessAction = () => {
  return dispatch => {
    dispatch(userLogoutSuccess());
  }
}

// const loginEffect = () => {
//     const res = {
//         token: 'adadsaadsdaasdsd',
//         user: {
//             name: 'Biworo Manunggal',
//             email: 'biworo.manunggal@gmail.com'
//         }
//     }
//     dispatch(attemptLoginSuccess(res));
// }
