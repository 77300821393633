import React, { Component } from 'react';

class NavLayout extends Component {
  render() {
    return (
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/*Left navbar links*/}
            <ul className="navbar-nav">
              <li className="nav-item">
                <span className="nav-link" data-widget="pushmenu" role="button">
                  <i className="fas fa-bars"/>
                </span>
              </li>
            </ul>

            {/* Right navbar links */}
          </nav>
    )
  }
}

export default NavLayout;
