import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import { useHistory, useParams } from "react-router";
import {fetchBankById, updateBank} from "../../../services/bank.service";
// import {fetchUsers} from "../../../services/panel.service";

const BankEditPage = () => {
  const [reqParams, setReqParams] = useState({
    kode: '',
    name: '',
    user: '',
    password: '',
    secret_code: '',
    url: {
      url: '',
      method: '',
      param: '',
      url_inq: '',
      url_pay: '',
      url_adv: '',
      url_rev: '',
    },
  });

  let { id } = useParams();
  const history = useHistory();

  useEffect((idParams = id) => {
    bankData(idParams);
  }, [id]);

  const bankData = (id) => {
    fetchBankById(id).then(res => {
      // console.log('res', res.data)
      let data = res.data.data;
      data.kode = res.data.data.kode ? res.data.data.kode : '';
      data.name = res.data.data.name ? res.data.data.name : '';
      data.user = res.data.data.user ? res.data.data.user : '';
      data.password = res.data.data.password ? res.data.data.password : '';
      data.secret_code = res.data.data.secret_code ? res.data.data.secret_code : '';
      if (res.data.data.url) {
        data.url = JSON.parse(res.data.data.url);
        data.url.url = res.data.data.url.url ? res.data.data.url.url : '';
        data.url.method = res.data.data.url.method ? res.data.data.url.method : '';
        data.url.param = res.data.data.url.param ? JSON.stringify(res.data.data.url.param) : '';
        data.url.url_inq = res.data.data.url.url_inq ? res.data.data.url.url_inq : '';
        data.url.url_pay = res.data.data.url.url_pay ? res.data.data.url.url_pay : '';
        data.url.url_adv = res.data.data.url.url_adv ? res.data.data.url.url_adv : '';
        data.url.url_rev = res.data.data.url.url_rev ? res.data.data.url.url_rev : '';
      } else {
        data.url = {};
        data.url.url = '';
        data.url.method = '';
        data.url.param = '';
        data.url.url_inq = '';
        data.url.url_pay = '';
        data.url.url_adv = '';
        data.url.url_rev = '';
      }
      delete data._id;
      delete data.__v;
      delete data.bankData;
      setReqParams(data)
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }

  const handleChange = (event) => {
    const params = {...reqParams}
    console.log(event.target.name)
    if (event.target.name === 'url' || event.target.name === 'param' ||
        event.target.name === 'method' || event.target.name === 'url_inq' ||
        event.target.name === 'url_pay' || event.target.name === 'url_adv' ||
        event.target.name === 'url_rev') {
      params.url[event.target.name] = event.target.value;
    } else {
      params[event.target.name] = event.target.value;
    }
    setReqParams(params);
    // console.log(params);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('reqParams', reqParams);
    console.log('id', id);

    // return false;
    try {
      let result = await updateBank(id, reqParams);
      console.log(result);
      if (result.status === 200 && result.data.message === 'updated') {
        alert('Success Update Bank');
        history.push('/panel/banks');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
    }
  }

  const handleBack = () => {
    // console.log(123);
    history.push('/panel/banks');
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="kode">Kode</label>
                <input
                    type="text"
                    className="form-control"
                    id="kode"
                    placeholder="Enter kode"
                    value={reqParams.kode}
                    name='kode'
                    onChange={handleChange}
                    required />
              </div>
              <div className="form-group">
                <label htmlFor="name-banks">Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="name-banks"
                    name='name'
                    value={reqParams.name}
                    onChange={handleChange}
                    placeholder="Enter name"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="user-bank">User</label>
                <input
                    type="text"
                    className="form-control"
                    id="user-bank"
                    name='user'
                    value={reqParams.user}
                    onChange={handleChange}
                    placeholder="Enter user"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password-bank">Password</label>
                <input
                    type="text"
                    className="form-control"
                    id="password-bank"
                    name='password'
                    placeholder="Enter password"
                    value={reqParams.password}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="secret-code">Secret Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="secret-code"
                    name='secret_code'
                    placeholder="Enter secret_code"
                    value={reqParams.secret_code}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="url-bank">Base Url</label>
                <input
                    type="text"
                    className="form-control"
                    id="url-bank"
                    placeholder="Enter base url"
                    name="url"
                    value={reqParams.url.url}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="method-bank">Method</label>
                <input
                    type="text"
                    className="form-control"
                    id="method-bank"
                    placeholder="Enter method"
                    name='method'
                    value={reqParams.url.method}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="params-bank">Params</label>
                <input
                    type="text"
                    className="form-control"
                    id="params-bank"
                    placeholder="Enter params"
                    name='param'
                    value={reqParams.url.param}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="url-inq-bank">Url Inq</label>
                <input
                    type="text"
                    className="form-control"
                    id="url-inq-bank"
                    placeholder="Enter Url Inq"
                    name='url_inq'
                    value={reqParams.url.url_inq}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="url-pay-bank">Url Pay</label>
                <input
                    type="text"
                    className="form-control"
                    id="url-pay-bank"
                    placeholder="Enter Url Pay"
                    name='url_pay'
                    value={reqParams.url.url_pay}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="url-adv-bank">Url Adv</label>
                <input
                    type="text"
                    className="form-control"
                    id="url-adv-bank"
                    placeholder="Enter Url Advice"
                    name='url_adv'
                    value={reqParams.url.url_adv}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="url-rev-bank">Url Rev</label>
                <input
                    type="text"
                    className="form-control"
                    id="url-rev-bank"
                    placeholder="Enter Url Reversal"
                    name='url_rev'
                    value={reqParams.url.url_rev}
                    onChange={handleChange}
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Update Users",
  location: '/panel/users-edit/id'
}

export default withHeader(pageSet)(BankEditPage)
